<template>
<div>
	<header>
		<div class="container">
			<div class="header">
				<div class="logo">
					<router-link to="/">
						<img src="/img/logo.png" alt="" />
					</router-link>
				</div>
				<nav class="menu-main">
					<MenuInner />
				</nav>
				<button class="menu-mobile-btn" @click="toggleMenu">
					{{ !isMobile ? 'Menu' : 'Close' }}
				</button>
			</div>
		</div>
	</header>
	<nav class="menu-mobile" :class="isMobile ? 'mobile-active' : '' ">
		<MenuInner />
	</nav>
</div>
	
</template>

<script>
export default {
	data() {
		return {
			isMobile: false,
		}
	},
	methods: {
		toggleMenu() {
			this.isMobile = !this.isMobile
		},
	},
	watch: {
		$route() {
			this.isMobile = false
		},
	},
};
</script>